import { Col, Container, Row } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';

const AddressSection = () => {
	return (
		<section className='py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]'>
			<Container>
				<Row className='items-center'>
					<Col className='col-12 col-lg-6 col-md-4 sm:mb-[30px]'>
						<h5 className='font-serif w-[50%] text-ttertiary font-medium mb-0 lg:w-[65%] md:w-[95%] xs:w-full'>
							How can we help you today?
						</h5>
					</Col>
					<Col className='col-12 col-lg-6 col-md-8'>
						<Row>
							<Col className='col-12 xs:mb-[30px]'>
								<span className='font-serif block text-tprimary font-medium mb-[10px]'>
									Hong Kong
								</span>
								<p className='w-[80%] mb-[5px] lg:w-[90%]'>
									Flat/Rm A 11/F Wing Tat Commercial Building 121-125 Wing Lok
									Street Sheung Wan, HK
								</p>
								<ScrollTo
									to='google-map-location'
									offset={-20}
									delay={0}
									spy={true}
									smooth={true}
									duration={800}
									className='uppercase text-sm text-ttertiary font-medium cursor-pointer border-b border-ttertiary hover:border-tprimary hover:text-tprimary'
								>
									View on google map
								</ScrollTo>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default AddressSection;
