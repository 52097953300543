import { Col, Container, Row } from 'react-bootstrap';
import { Parallax } from 'react-scroll-parallax';
import Buttons from '../../../Litho/Components/Button/Buttons';
import IconWithText from '../../../Litho/Components/IconWithText/IconWithText';
import colors from '../../../constants/colors';
import {
	fadeIn,
	fadeInDown,
	fadeInLeft,
	fadeInRight,
	fadeInUp,
} from '../../../Litho/Functions/GlobalAnimations';
import { m } from 'framer-motion';

const IconWithTextData = [
	{
		icon: 'fa fa-balance-scale text-[75px]',
		title: 'Integrity at Every Step',
		content:
			'We uphold the highest standards of honesty and transparency in all our dealings.',
		animation: fadeInRight,
		animationDuration: 0.9,
		animationRepeat: false,
	},
	{
		icon: 'fa fa-handshake text-[35px]',
		title: 'Customer-Centered Approach',
		content:
			'Your satisfaction is our goal. We provide personalized services to meet your needs.',
		animation: fadeInUp,
		animationDuration: 0.9,
		animationRepeat: false,
	},
	{
		icon: 'fa fa-award text-[35px]',
		title: 'Sustained Excellence',
		content:
			'We continuously strive for innovation and quality, ensuring long-term value for all stakeholders.',
		animation: fadeInLeft,
		animationDuration: 0.9,
		animationRepeat: false,
	},
];

const MissionAndValuesSection = () => {
	return (
		<div className='py-[80px] lg:py-[90px] md:py-[75px] xs:py-[50px] overflow-hidden relative'>
			<Parallax
				className='lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 md:-top-[30px] w-full h-full'
				style={{
					backgroundImage: `url(/assets/img/about-us-page/missions-bg.jpg)`,
				}}
			></Parallax>
			<div className='absolute h-full w-full opacity-80 top-0 left-0 bg-gradient-to-t from-transparent to-black'></div>
			<Container>
				<Row className='justify-center'>
					<Col lg={7} sm={9} className='relative text-center mb-[4.5rem]'>
						<m.div
							{...{
								...fadeInDown,
								transition: { duration: 0.9 },
								viewport: { once: false },
							}}
						>
							<div className='text-xmd text-slate-300 mb-[20px] font-serif'>
								Guided by values, driven by a mission of trust and integrity.
							</div>
							<h5 className='font-serif text-white font-medium mb-10 sm:mb-[30px]'>
								Experts in Gold Trading and Premium Products
							</h5>
							<Buttons
								to='/products'
								className='mx-3 font-medium font-serif text-white uppercase hover:text-darkgray rounded-[50px] bg-tsecondary btn-slide-right'
								size='md'
								color='#fff'
								themeColor={colors.tprimary}
								title='Our Services'
							/>
						</m.div>
					</Col>
				</Row>
				<Row className='justify-center'>
					<IconWithText
						grid='gap-y-10'
						theme='icon-with-text-06'
						className='relative col-12 col-lg-4 col-md-6 col-sm-9 lg:px-0'
						data={IconWithTextData}
						animation={fadeInRight}
						animationDelay={0.1}
					/>
				</Row>
			</Container>
		</div>
	);
};

export default MissionAndValuesSection;
