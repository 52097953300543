import { useEffect, useRef, memo } from 'react';

function AdvancedChart() {
	const container = useRef();

	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
		script.type = 'text/javascript';
		script.async = true;
		script.innerHTML = `
			{
			  "autosize": true,
			  "symbol": "XAUUSD",
			  "interval": "H",
			  "timezone": "exchange",
			  "theme": "dark",
			  "style": "1",
			  "locale": "en",
			  "calendar": true,
			  "withdateranges": true,
			  "allow_symbol_change": false,
			  "save_image": false,
			  "details": true,
			  "hotlist": true,
			  "calendar": true,
			  "support_host": "https://www.tradingview.com"
			}`;
		container.current.appendChild(script);
	}, []);

	return (
		<div
			className='tradingview-widget-container__widget w-full sm:[calc(100% + 15px)]'
			style={{
				height: 'calc(100% - 32px)',
			}}
			ref={container}
		/>
	);
}

export default memo(AdvancedChart);
