import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { fadeInDown } from '../../../Litho/Functions/GlobalAnimations';
import { m } from 'framer-motion';

const HeroSection = () => {
	return (
		<div className='h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative'>
			<Parallax
				className='lg-no-parallax bg-cover cover-background absolute top-[-80px] left-0 w-full h-[100vh]'
				translateY={[-40, 40]}
				style={{
					backgroundImage: `url(/assets/img/about-us-page/hero.jpg)`,
				}}
			></Parallax>
			<div className='absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray'></div>
			<Container>
				<Row className='items-center justify-center'>
					<Col md={8} xl={6} lg={7} sm={9} className='relative text-center'>
						<m.h1
							className='inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif'
							{...{
								...fadeInDown,
								transition: { duration: 0.7, delay: 0.2 },
								viewport: { once: false },
							}}
						>
							About our company
						</m.h1>
						<m.h2
							className='font-serif text-white -tracking-[1px] text-[3.9rem] sm:text-[3rem] font-medium mb-0 sm:-tracking-[1px]'
							{...{
								...fadeInDown,
								transition: { duration: 0.7, delay: 0.2 },
								viewport: { once: false },
							}}
						>
							Crafting excellence, Building trust
						</m.h2>
					</Col>
					<Link
						to='about'
						offset={0}
						delay={0}
						spy={true}
						smooth={true}
						duration={800}
						className='absolute bottom-[50px] left-1/2 w-auto inline-block p-0 -translate-x-1/2 sm:hidden cursor-pointer'
					>
						<i className='ti-arrow-down text-lg leading-[1] text-white bg-[#000000b3] p-[15px] xs:p-[10px] rounded-full flex justify-center items-center'></i>
					</Link>
				</Row>
			</Container>
		</div>
	);
};

export default HeroSection;
