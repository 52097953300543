import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';

const NewsSection = () => {
	const container = useRef();
	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
		script.async = true;
		script.innerHTML = JSON.stringify({
			feedMode: 'symbol',
			symbol: 'OANDA:XAUUSD',
			isTransparent: true,
			displayMode: 'adaptive',
			width: '100%',
			height: '100%',
			colorTheme: 'dark',
			locale: 'en',
		});

		container.current.appendChild(script);
	}, []);

	return (
		<Container className='px-0'>
			<h3 className='text-ttertiary text-xl font-medium font-sans text-center mt-16'>
				Latest News
			</h3>
			<div className='h-[430px]'>
				<div className='tradingview-widget-container' ref={container}>
					<div className='tradingview-widget-container__widget'></div>
				</div>
			</div>
		</Container>
	);
};

export default NewsSection;
