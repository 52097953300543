import { m } from 'framer-motion';
import { Image, Row } from 'react-bootstrap';
import Lists from '../../../../Litho/Components/Lists/Lists';
import colors from '../../../../constants/colors';
import Buttons from '../../../../Litho/Components/Button/Buttons';
import useResponsiveScreen from '../../../../hooks/useResponsiveScreen';
import {
	fadeInLeft,
	fadeInRight,
} from '../../../../Litho/Functions/GlobalAnimations';

const Product = ({
	title,
	description,
	listData,
	buttonText,
	buttonTo,
	imageUrl,
	imageWidth,
	mobileImageWidth,
	reverse,
}) => {
	const { isMobile } = useResponsiveScreen();

	return (
		<Row className='items-center justify-center'>
			<m.div
				className={`col-lg-5 col-md-10 flex justify-center items-center mb-28 sm:mb-10 ${
					reverse ? 'notMobile:order-last' : ''
				} sm:order-first`}
				{...{
					...(reverse ? fadeInLeft : fadeInRight),
					transition: { duration: 0.9 },
					viewport: { once: false },
				}}
			>
				<Image
					src={imageUrl}
					alt={title}
					style={{ maxWidth: isMobile ? mobileImageWidth : imageWidth }}
				/>
			</m.div>
			<m.div
				className='col-lg-6 col-md-10 md:mb-20 consulting-about'
				{...{
					...(reverse ? fadeInRight : fadeInLeft),
					transition: { duration: 0.9 },
					viewport: { once: false },
				}}
			>
				{title && (
					<h2 className='heading-5 font-serif font-medium text-darkgray tracking-[-1px] mb-16 w-[80%] lg:w-[90%] md:mb-12 xs:mb-16 xs:w-full'>
						<span className='text-ttertiary font-semibold text-decoration-line-bottom-thick'>
							{title}
						</span>
					</h2>
				)}
				{description && (
					<p className=' mb-[40px] lg:w-[90%] md:mb-[45px] xs:mb-[25px]'>
						{description}
					</p>
				)}
				{listData && <Lists theme='list-style-03' data={listData} />}
				{buttonText && (
					<div className='mt-[50px] md:mt-[25px] xs:mt-[20px] landscape:md:mt-[40px]'>
						<Buttons
							to={buttonTo}
							className='mr-[35px] btn-fill btn-fancy rounded-full font-medium font-serif uppercase md:mb-[15px] xs:mb-0'
							themeColor={colors.tprimary}
							color='#fff'
							size='sm'
							title={buttonText}
						/>
					</div>
				)}
			</m.div>
		</Row>
	);
};

export default Product;
