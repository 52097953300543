import { Container, Row } from 'react-bootstrap';
import Product from './Product';
import productsData from './productsData';

const ProductsSection = () => {
	return (
		<section
			className='py-[160px] overflow-hidden bg-red-400 cover-background lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]'
			style={{
				backgroundImage: `url('/assets/img/home-page/dots-pattern.png')`,
			}}
		>
			<Container>
				<Row className='items-center justify-center sm:mb-24'>
					<h3 className='text-ttertiary text-2xl sm:text-xl text-center font-medium font-sans'>
						Our Services
					</h3>
				</Row>
				<div className='flex flex-col gap-40 sm:gap-4'>
					{productsData.map((service, i) => {
						return <Product key={i} reverse={i % 2 === 0} {...service} />;
					})}
				</div>
			</Container>
		</section>
	);
};

export default ProductsSection;
