import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';

const HeroSection = () => {
	return (
		<m.section
			className='py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]'
			style={{
				backgroundImage: 'url(assets/img/home-page/line-pattern.png)',
				backgroundSize: 'cover',
			}}
		>
			<Container className='h-[300px] sm:h-[250px] flex justify-center sm:justify-end'>
				<Row xs={1} md={2} className='items-end justify-end sm:flex-col'>
					<Col className='p-0 sm:!px-[15px] sm:mb-[20px]'>
						<h1 className='text-xmd !leading-[20px] pl-[90px] pr-[15px] text-tprimary font-serif mb-0 relative sm:pl-[55px] md:mb-0 sm:mb-[20px]'>
							<span className='h-[1px] absolute text-tprimary top-[12px] left-0 w-[70px] bg-ttertiary sm:w-[40px] sm:top-1/2'></span>
							Contact Us
						</h1>
					</Col>
					<Col className='p-0 sm:!px-[15px]'>
						<h4 className='font-medium text-ttertiary font-serif mb-0'>
							Partner with us for excellence in every interaction
						</h4>
					</Col>
				</Row>
			</Container>
		</m.section>
	);
};

export default HeroSection;
