import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import {
	fadeIn,
	fadeInLeft,
	fadeInRight,
} from '../../../Litho/Functions/GlobalAnimations';

const MarketExpertiseSection = () => {
	return (
		<m.section
			className='pt-[130px] lg:pt-[90px] md:pt-[75px] xs:pt-[50px] overflow-hidden'
			{...fadeIn}
		>
			<Container>
				<Row className='items-center'>
					<Col xl={5} lg={6} className='pb-12 lg:pb-28 sm:pb-[50px]'>
						<m.div
							{...{
								...fadeInRight,
								transition: { duration: 0.9 },
								viewport: { once: false },
							}}
						>
							<div className='text-xmd mb-[20px] font-serif'>
								Our Deep Market Insight
							</div>
							<h6 className='font-serif text-ttertiary font-medium mb-[5.5rem]'>
								Years of expertise navigating the gold market’s complexities.
							</h6>
						</m.div>
						<Row>
							<Col>
								<m.div
									className='flex'
									{...{
										...fadeInRight,
										transition: { duration: 0.6, delay: 0.2 },
										viewport: { once: false },
									}}
								>
									<div className='mr-[35px]'>
										<i className='line-icon-Idea-5 text-[50px] text-ttertiary'></i>
									</div>
									<div className='feature-box-content '>
										<span className='text-tprimary block mb-[10px] font-serif font-medium'>
											In-Depth Analysis
										</span>
										<p className='w-[95%]'>
											Our expertise is built on rigorous market analysis,
											ensuring you stay ahead in the ever-evolving gold
											landscape.
										</p>
									</div>
								</m.div>
							</Col>
							<Col className='col-12'>
								<m.div
									className='h-[1px] bg-[#ededed] my-[40px] w-full xs:my-[30px]'
									{...{
										...fadeIn,
										transition: { duration: 0.7, delay: 0.2 },
										viewport: { once: false },
									}}
								></m.div>
							</Col>
							<Col>
								<m.div
									className='flex'
									{...{
										...fadeInRight,
										transition: { duration: 0.7, delay: 0.4 },
										viewport: { once: false },
									}}
								>
									<div className='mr-[35px]'>
										<i className='line-icon-Medal-2 text-[50px] text-ttertiary'></i>
									</div>
									<div className='feature-box-content '>
										<span className='text-tprimary block mb-[10px] font-serif font-medium'>
											Trusted Market Knowledge
										</span>
										<p className='w-[95%]'>
											With a focus on accuracy and foresight, we provide
											insights that drive informed decisions for long-term
											value.
										</p>
									</div>
								</m.div>
							</Col>
						</Row>
					</Col>
					<Col
						lg={6}
						xl={{ offset: 1 }}
						className='align-self-end text-center text-xl-start'
					>
						<m.img
							className='md:mx-auto'
							loading='lazy'
							src='/assets/img/about-us-page/market-expert.jpeg'
							width='504'
							height='666'
							alt='market-expert'
							{...{
								...fadeInLeft,
								transition: { duration: 0.7, delay: 0.2 },
								viewport: { once: false },
							}}
						/>
					</Col>
				</Row>
			</Container>
		</m.section>
	);
};

export default MarketExpertiseSection;
