import { Col, Container, Row } from 'react-bootstrap';
import AdvancedChart from './Charts/AdvancedChart';
import TechnicalAnalysis from './Charts/TechnicalAnalysis';

const AnalysisSection = () => {
	return (
		<div className='container flex flex-col gap-4 items-center mt-28 sm:px-0'>
			<h3 className='text-ttertiary sm:!text-xl text-xl font-medium font-sans'>
				Technical Analysis
			</h3>
			<Container>
				<Row xs={1} md={2} className='flex-wrap'>
					<Col className='col-md-8 col-sm-12 h-[430px] sm:px-0'>
						<AdvancedChart />
					</Col>
					<Col className='col-md-4 col-sm-12 h-[400px] sm:px-0'>
						<TechnicalAnalysis />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default AnalysisSection;
