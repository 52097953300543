import { Helmet } from 'react-helmet-async';

const HomePageHelmet = () => {
	return (
		<Helmet>
			<title>Multilines Hong Kong</title>
			<meta content='Multilines specializes in trading and offering premium gold products, including gold bars and fine jewelry. Discover timeless value with trusted expertise in gold.' />
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.multilineshk.com' />
			<meta
				property='og:title'
				content='Multilines - Trusted Experts in Gold Trading and Fine Jewelry'
			/>

			<meta
				property='og:image'
				content='https://www.multilineshk.com/assets/img/multilines-logo.png'
			/>
			<meta
				property='og:description'
				content='Multilines specializes in trading and offering premium gold products, including gold bars and fine jewelry. Discover timeless value with trusted expertise in gold.'
			/>
			<meta
				name='keywords'
				content='gold trading, gold bars, gold jewelry, precious metals, gold investment, multilines, hong kong, buy gold online, gold products, fine jewelry'
			/>

			<meta
				name='description'
				content='Multilines specializes in trading and offering premium gold products, including gold bars and fine jewelry. Discover timeless value with trusted expertise in gold.'
			/>
			<meta property='og:site_name' content='Multilines Hong Kong' />
			<meta property='og:locale' content='en_US' />
			<link rel='canonical' href='https://www.multilineshk.com' />
		</Helmet>
	);
};

export default HomePageHelmet;
