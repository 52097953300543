import React, { useEffect, useRef, memo } from 'react';

const TickerTape = () => {
	const container = useRef();

	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
		script.type = 'text/javascript';
		script.async = true;
		script.innerHTML = `
			{
  "symbols": [
    {
      "description": "Gold",
      "proName": "CAPITALCOM:GOLD"
    }
  ],
  "showSymbolLogo": true,
  "isTransparent": true,
  "displayMode": "adaptive",
  "colorTheme": "dark",
  "locale": "en"
}`;
		container.current.appendChild(script);
	}, []);
	return (
		<div class='tradingview-widget-container' ref={container}>
			<div class='tradingview-widget-container__widget'></div>
		</div>
	);
};

export default memo(TickerTape);
