import { Col, Container } from 'react-bootstrap';
import { m } from 'framer-motion';
import Buttons from '../Litho/Components/Button/Buttons';
import {
	fadeIn,
	fadeInBlur,
	zoomIn,
} from '../Litho/Functions/GlobalAnimations';
import colors from '../constants/colors';
import PageWrapper from '../components/basic/PageWrapper';

const PageNotFoundPage = () => {
	return (
		<PageWrapper>
			<section
				className='cover-background overflow-hidden flex items-center justify-center p-0'
				style={{
					backgroundImage: `url("/assets/img/contact-us-page/square-pattern.png")`,
				}}
			>
				<Container>
					<m.div
						className='row items-stretch full-screen justify-center'
						{...fadeInBlur}
					>
						<Col
							xl={6}
							lg={7}
							md={8}
							className='col-12 text-center flex items-center justify-center flex-col'
						>
							<h6 className='font-serif text-tprimary font-semibold -tracking-[1px] mb-[10px] uppercase'>
								Ooops!
							</h6>
							<h1 className='font-serif text-[230px] leading-[230px] font-bold tracking-[-5px] text-ttertiary mb-24 lg:text-[170px] lg:leading-[170px] md:text-[130px] md:leading-[130px] md:mb-16 sm:text-[100px] sm:leading-[100px] xs:text-[55px] xs:leading-[55px] xl:text-[200px] xl:leading-[200px]'>
								404
							</h1>
							<span className='font-serif font-medium text-tprimary block mb-[20px]'>
								This page could not be found!
							</span>
							<Buttons
								className='font-bold font-serif rounded-full text-xl uppercase mb-0'
								to='/'
								themeColor={[
									colors.tprimary,
									colors.ttertiary,
									colors.tsecondary,
								]}
								size='xl'
								color={colors.tsecondary}
								title='Back to homepage'
							/>
						</Col>
					</m.div>
				</Container>
			</section>
		</PageWrapper>
	);
};

export default PageNotFoundPage;
