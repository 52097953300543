import { Helmet } from 'react-helmet-async';

const AboutPageHelmet = () => {
	return (
		<Helmet>
			<title>About</title>
			<meta
				name='description'
				content='We are Crafting excellence, Building trust in gold market'
			/>
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.multilineshk.com/about' />
			<meta property='og:title' content='About - What we do' />
			<meta
				property='og:image'
				content='https://www.multilineshk.com/assets/img/multilines-logo.png'
			/>
			<meta property='og:description' content='' />
			<meta property='og:site_name' content='Multilines Hong Kong' />
			<meta property='og:locale' content='en_US' />
			<link rel='canonical' href='https://www.multilineshk.com/about' />
		</Helmet>
	);
};

export default AboutPageHelmet;
