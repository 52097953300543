import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import { PropTypes } from 'prop-types';
import colors from '../../../constants/colors';

import '../../../Assets/scss/components/_textbox.scss';
import { fadeInUp } from '../../../Litho/Functions/GlobalAnimations';

const TextBox = (props) => {
	return (
		<Row
			className={`${props.grid} ${props.theme} ${props.className} text-neutral-300`}
			style={{ '--base-color': colors.tprimary }}
		>
			{props.data.map((item, i) => {
				return (
					<m.span
						{...{
							...fadeInUp,
							transition: { duration: 0.9 },
							viewport: { once: false },
						}}
					>
						<Col
							className={`feature-box${
								props.className ? ` ${props.className} ` : ''
							}`}
							key={i}
						>
							<m.div
								className='feature-box-content w-full'
								{...{
									...props.animation,
									transition: { delay: i * props.animationDelay },
								}}
							>
								{props.theme === 'text-box-style-01' ? (
									<>
										<h2>{item.number}</h2>
										<span>{item.title}</span>
									</>
								) : (
									<>
										<span className='text-red-600'>{item.title}</span>

										<div className='feature-box-number !text-tprimary'>
											<span></span>
											{/* {item.number} */}
										</div>
									</>
								)}
								<p>{item.description}</p>
							</m.div>
						</Col>
					</m.span>
				);
			})}
		</Row>
	);
};

TextBox.defaultProps = {
	theme: 'text-box-style-01',
	animationDelay: 0.3,
};

TextBox.propTypes = {
	className: PropTypes.string,
	theme: PropTypes.string,
	animation: PropTypes.object,
	animationDelay: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	grid: PropTypes.string,
	data: PropTypes.arrayOf(
		PropTypes.exact({
			number: PropTypes.string,
			title: PropTypes.string,
			description: PropTypes.string,
		}),
	),
};

export default memo(TextBox);
