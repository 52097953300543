const productsData = [
	{
		id: 1,
		title: '1 Gram Gold Plate',
		description:
			'Discover the allure of fine gold with our exquisite 1 Gram Fine Gold bar. Perfectly crafted for collectors, investors, and those new to the world of precious metals, this gold bar embodies purity, value, and elegance. Whether you’re starting a new investment portfolio or adding to an existing collection, the 1 Gram Fine Gold bar is an ideal choice.',
		buttonText: 'Contact Us',
		buttonTo: '/contact',
		imageUrl: '/assets/img/home-page/services/1g.png',
		imageWidth: '230px',
		mobileImageWidth: '150px',
		listData: [
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Weight: </b>1 gram
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Purity: </b>99.99% (24 Karat)
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Packaging: </b>Securely packaged to
						prevent damage and tampering. Comes with a certificate of
						authenticity.
					</span>
				),
			},
		],
	},
	{
		id: 2,
		title: '500g Gold Plate',
		description:
			'Many manufacturers worldwide produce small cast gold bars, weighing 500 g and less. Few small cast gold bars in troy ounces are now made by accredited refiners. The Perth Mint (Australia) is the only accredited manufacturer to issue an extensive range: from 50oz to 1/2 oz.',
		buttonText: 'Contact Us',
		buttonTo: '/contact',
		imageUrl: '/assets/img/home-page/services/500g.png',
		imageWidth: '230px',
		mobileImageWidth: '150px',
		listData: [
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Weight: </b>500 grams
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Purity: </b>99.99% (24 Karat)
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Dimensions: </b>Varies slightly by
						mint, typically around 120mm x 55mm x 9mm
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Manufacturer: </b>Certified and
						recognized international mints
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Serial Number: </b>Unique serial
						number inscribed for authenticity
					</span>
				),
			},
		],
	},
	{
		id: 3,
		title: '1kg Gold Plate',
		description:
			'The kilo bar (1000 g) is the world’s most widely traded small gold bar. While most have a flat “international” shape, traditional kilo bars in the shape of a “brick” are still available, notably in Europe.',
		buttonText: 'Contact Us',
		buttonTo: '/contact',
		imageUrl: '/assets/img/home-page/services/1000g.png',
		imageWidth: '340px',
		mobileImageWidth: '240px',
		listData: [
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Weight: </b>1000 grams (1 kilogram)
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Purity: </b>99.99% (24 Karat)
					</span>
				),
			},
			{
				icon: 'fa fa-circle !text-[10px] !text-ttertiary',
				content: (
					<span className='text-neutral-400'>
						<b className='text-ttertiary'>Dimensions: </b>Varies by
						manufacturer, typically around 117mm x 53mm x 8.5mm
					</span>
				),
			},
		],
	},
	{
		id: 4,
		title: 'Gold Jewelry',
		description:
			'Welcome to our exclusive collection of gold jewelry, where timeless elegance meets unparalleled craftsmanship. Each piece in our collection is meticulously designed to celebrate the beauty and luxury of gold, offering you stunning accessories that enhance your style and sophistication. Whether you’re looking for a statement piece for a special occasion or a subtle touch of glamour for everyday wear, our gold jewelry is the perfect choice.',
		buttonText: 'Contact Us',
		buttonTo: '/contact',
		imageUrl: '/assets/img/home-page/services/jewelry.png',
		imageWidth: '360px',
		mobileImageWidth: '230px',
	},
];

export default productsData;
