import { m } from 'framer-motion';
import React, { useEffect, useRef, memo } from 'react';
import {
	fadeInLeft,
	fadeInRight,
} from '../../../Litho/Functions/GlobalAnimations';

function GoldMonthlyPriceChart() {
	const container = useRef();

	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
		script.type = 'text/javascript';
		script.async = true;
		script.innerHTML = `
        {
          "symbols": [
            [
              "CAPITALCOM:GOLD|1M"
            ]
          ],
          "chartOnly": false,
          "width": "100%",
          "height": "100%",
          "locale": "en",
          "colorTheme": "dark",
          "autosize": true,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          "fontSize": "12",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "area",
          "maLineColor": "#2962FF",
          "maLineWidth": 1,
          "maLength": 9,
          "headerFontSize": "medium",
          "fontColor": "rgba(186, 128, 35, 1)",
          "gridLineColor": "rgba(186, 128, 35, 1)",
          "backgroundColor": "rgba(30, 30, 30, 1)",
          "widgetFontColor": "rgba(233, 192, 95, 1)",
          "lineWidth": 2,
          "lineType": 0,
          "dateRanges": [
            "1d|1",
            "1m|30",
            "3m|60",
            "12m|1D",
            "60m|1W",
            "all|1M"
          ]
        }`;
		container.current.appendChild(script);
	}, []);

	return (
		<m.section
			id='monthly-price-chart'
			className='container flex flex-col gap-4 items-center justify-center overflow-x-hidden py-[90px] lg:py-[90px] md:py-[75px] sm:py-[50px]'
			{...{
				...fadeInRight,
				transition: { duration: 0.9 },
				viewport: { once: false },
			}}
		>
			<h3 className='text-ttertiary text-xl font-medium font-sans'>
				Monthly Gold Price Chart
			</h3>
			<div className='h-[600px] w-full overflow-hidden border-ttertiary border-2 rounded-3xl'>
				<div className='tradingview-widget-container' ref={container}>
					<div className='tradingview-widget-container__widget'></div>
					<div className='tradingview-widget-copyright'></div>
				</div>
			</div>
		</m.section>
	);
}

export default memo(GoldMonthlyPriceChart);
