import { Helmet } from 'react-helmet-async';

const ProductsPageHelmet = () => {
	return (
		<Helmet>
			<title>Products</title>
			<meta
				name='description'
				content='Explore our selection of gold bars in various weights, from 1 gram to 1 kilogram, along with exquisite gold jewelry.'
			/>
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.multilineshk.com/products' />
			<meta
				property='og:title'
				content='Multilines | Gold Bars and Fine Jewelry'
			/>
			<meta
				property='og:image'
				content='https://www.multilineshk.com/assets/img/multilines-logo.png'
			/>
			<meta
				property='og:description'
				content='Premium gold bars and jewelry crafted for value and elegance. Discover our gold products today.'
			/>
			<meta property='og:site_name' content='Multilines' />
			<meta property='og:locale' content='en_US' />
		</Helmet>
	);
};

export default ProductsPageHelmet;
