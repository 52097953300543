import React, { useEffect, useRef, memo } from 'react';

function TechnicalAnalysis() {
	const container = useRef();

	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
		script.type = 'text/javascript';
		script.async = true;
		script.innerHTML = `
			{
  "interval": "1h",
  "width": "100%",
  "isTransparent": true,
  "height": "100%",
  "symbol": "GOLD",
  "showIntervalTabs": true,
  "displayMode": "single",
  "locale": "en",
  "colorTheme": "dark"
}`;
		container.current.appendChild(script);
	}, []);

	return (
		<div className='tradingview-widget-container' ref={container}>
			<div class='tradingview-widget-container__widget'></div>
		</div>
	);
}

export default memo(TechnicalAnalysis);
