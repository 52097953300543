import { Helmet } from 'react-helmet-async';

const ContactPageHelmet = () => {
	return (
		<Helmet>
			<title>Contact Us</title>
			<meta
				name='description'
				content='Get in touch with Multilines for inquiries about gold bars, fine jewelry, or any other gold-related services.'
			/>
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.multilineshk.com/contact' />
			<meta
				property='og:title'
				content='Contact Multilines | Gold Trading and Jewelry'
			/>
			<meta
				property='og:image'
				content='https://www.multilineshk.com/img/logo.png'
			/>
			<meta
				property='og:description'
				content='Connect with Multilines for premium gold trading and fine jewelry. Our team is here to assist you.'
			/>
			<meta property='og:site_name' content='Multilines' />
			<meta property='og:locale' content='en_US' />
		</Helmet>
	);
};

export default ContactPageHelmet;
