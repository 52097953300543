import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { fadeInDown } from '../../../Litho/Functions/GlobalAnimations';
import { m } from 'framer-motion';
import TickerTape from './Charts/TickerTape';

const HeroSection = () => {
	return (
		<div className='h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative'>
			<Parallax
				className='lg-no-parallax bg-cover cover-background absolute top-[-80px] left-0 w-full h-[100vh]'
				translateY={[-40, 40]}
				style={{
					backgroundImage: `url(/assets/img/news-and-analysis-page/gold-charts.png)`,
				}}
			></Parallax>
			<div className='absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray'></div>
			<Container>
				<Row className='items-center justify-center'>
					<Col md={8} xl={6} lg={7} sm={9} className='relative text-center'>
						<m.h1
							className='inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif'
							{...{
								...fadeInDown,
								transition: { duration: 0.7, delay: 0.2 },
								viewport: { once: false },
							}}
						>
							Gold Market Analysis & News
						</m.h1>
						<m.h2
							className='font-serif text-white -tracking-[1px] text-[3.9rem] sm:text-[3rem] font-medium mb-0 sm:-tracking-[1px]'
							{...{
								...fadeInDown,
								transition: { duration: 0.7, delay: 0.2 },
								viewport: { once: false },
							}}
						>
							Market Trends and Expert Analysis
						</m.h2>
					</Col>
				</Row>
				<div className='absolute bottom-[0px] w-full left-1/2 inline-block p-0 -translate-x-1/2 sm:hidden cursor-pointer'>
					<TickerTape />
				</div>
			</Container>
		</div>
	);
};

export default HeroSection;
