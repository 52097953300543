import PageWrapper from '../components/basic/PageWrapper';
import { m } from 'framer-motion';
import { fadeIn } from '../Litho/Functions/GlobalAnimations';
import GoogleMap from '../Litho/Components/GoogleMap/GoogleMap';
import HeroSection from '../components/components/ContactUsPage/HeroSection';
import AddressSection from '../components/components/ContactUsPage/AddressSection';
import ContactForm from '../components/components/ContactUsPage/ContactForm';
import ContactPageHelmet from '../components/components/ContactUsPage/ContactPageHelmet';

const ContactUsPage = () => {
	return (
		<>
			<ContactPageHelmet />
			<PageWrapper>
				<HeroSection />

				<AddressSection />

				<ContactForm />

				<m.section {...fadeIn} id='google-map-location'>
					<GoogleMap
						className='h-[500px] xs:h-[300px]'
						location='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.782677158952!2d114.14849687514202!3d22.286220679696694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007c3749b9c9%3A0x7e2603971c10e038!2sWing%20Tat%20Commercial%20Building%2C%20121-125%20Wing%20Lok%20St%2C%20Sheung%20Wan%2C%20Hong%20Kong!5e0!3m2!1sen!2s!4v1729577737165!5m2!1sen!2s'
					/>
				</m.section>
			</PageWrapper>
		</>
	);
};

export default ContactUsPage;
