import PageWrapper from '../components/basic/PageWrapper';
import HeroSection from '../components/components/AboutUsPage/HeroSection';
import OurJourney from '../components/components/AboutUsPage/OurJourney';
import MissionAndValuesSection from '../components/components/AboutUsPage/MissionAndValuesSection';
import MarketExpertiseSection from '../components/components/AboutUsPage/MarketExpertiesSection';
import AboutPageHelmet from '../components/components/AboutUsPage/AboutPageHelmet';

const AboutUsPage = () => {
	return (
		<>
			<AboutPageHelmet />
			<PageWrapper>
				<HeroSection />
				<OurJourney />
				<MissionAndValuesSection />
				<MarketExpertiseSection />
			</PageWrapper>
		</>
	);
};

export default AboutUsPage;
