const activitiesData = [
	{
		tabTitle: 'INDUSTRY',
		subtitle: 'STAYING ABREAST OF TRENDS',
		tabicons: '',
		title: 'Industry Participation and Events',
		img: 'assets/img/home-page/activities/industry1.jpg',
		content:
			'At Multilines Hong Kong, we actively participate in industry conferences, seminars, and trade shows to stay abreast of the latest trends and developments in the gold market. From networking opportunities to thought leadership sessions, these events allow us to connect with industry peers, share insights, and contribute to the advancement of the gold trading industry.',
		buttonTitle: 'Learn More',
		buttonLink: '/industry-events',
	},
	{
		tabTitle: 'CSR',
		subtitle: 'GIVING BACK',
		tabicons: '',
		title: 'Corporate Social Responsibility Initiatives',
		img: 'assets/img/home-page/activities/csr.jpg',
		content:
			'We believe in giving back to the communities where we operate. Through our corporate social responsibility initiatives, we support various charitable causes, environmental conservation efforts, and community development projects. Whether it’s sponsoring local events, supporting educational programs, or promoting sustainable practices, we are committed to making a positive impact beyond our business operations.',
		buttonTitle: 'Explore Initiatives',
		buttonLink: '/csr-initiatives',
	},
	{
		tabTitle: 'EDUCATION',
		subtitle: 'EMPOWERING KNOWLEDGE',
		tabicons: '',
		title: 'Educational Outreach and Training',
		img: 'assets/img/home-page/activities/education.png',
		content:
			'Empowering individuals with knowledge and skills is central to our mission at Multilines Hong Kong. We offer educational outreach programs and training workshops to educate the public about the benefits of gold investment, trading strategies, and responsible trading practices. By providing access to valuable resources and expert guidance, we aim to empower individuals to make informed decisions and achieve financial security through gold trading.',
		buttonTitle: 'Join Our Programs',
		buttonLink: '/educational-outreach',
	},
	{
		tabTitle: 'RESEARCH',
		subtitle: 'IN-DEPTH INSIGHTS',
		tabicons: '',
		title: 'Market Research and Analysis',
		img: 'assets/img/home-page/activities/research.jpg',
		content:
			'In the fast-paced world of gold trading, staying ahead of the curve requires in-depth market research and analysis. Our team of experts conducts thorough market research, analyzes key trends and data points, and shares actionable insights with our clients and stakeholders. Whether it’s market reports, industry analysis, or expert commentary, you can rely on us for timely and relevant information to guide your trading decisions.',
		buttonTitle: 'Get the Latest Research',
		buttonLink: '/market-research',
	},
	{
		tabTitle: 'INNOVATION',
		subtitle: 'EMBRACING TECHNOLOGY',
		tabicons: '',
		title: 'Continuous Innovation and Technology Integration',
		img: 'assets/img/home-page/activities/innovation.jpg',
		content:
			'Innovation is at the heart of everything we do at Multilines Hong Kong. We continuously invest in cutting-edge technologies and digital solutions to enhance our trading platforms, streamline processes, and improve customer experience. From mobile trading apps to algorithmic trading systems, we leverage the latest advancements to provide our clients with the tools they need to succeed in the dynamic world of gold trading.',
		buttonTitle: 'Discover Our Technology',
		buttonLink: '/technology-innovation',
	},
];

export default activitiesData;
