import { Col, Container, Row } from 'react-bootstrap';
import { Footer as LithoFooter } from '../../Litho/Components/Footers/Footer';

const Footer = () => {
	return (
		<LithoFooter
			parallax={{ desktop: true, lg: false }}
			className={`py-[5.5rem] md:py-[8.5rem] bg-zinc-900 footer-style-11`}
			style={{
				backgroundImage: "url('/assets/img/home-page/dots-pattern.png')",
			}}
		>
			<Container>
				<Row className='flex justify-center'>
					<Col
						lg={8}
						className='text-center flex flex-col gap-3 justify-center'
					>
						<div className='flex flex-col items-center gap-4'>
							<img
								width='190'
								height='36'
								loading='lazy'
								src='/assets/img/multilines-logo.png'
								alt='logo'
								className='default-logo cursor-pointer'
								onClick={() => window.location.replace('/')}
							/>
							<h4 className='font-serif font-light mb-[10px] block text-lg sm:text-md text-white'>
								Multilines Hong Kong is a leading trader and wholesaler of pure
								Gold Bars and standard products made of Gold.
							</h4>
						</div>
						<h4 className='font-serif font-semibold text-[2.25rem] sm:text-lg leading-[3.4rem]'>
							<a
								aria-label='mail'
								rel='noreferrer'
								className='border-none md:mb-[15px] no-underline bg-text-gradient bg-clip-text text-transparent hover:text-tprimary'
								href='mailto:info@domain.com'
							>
								info@multilineshk.com
							</a>
						</h4>
					</Col>
				</Row>
			</Container>
		</LithoFooter>
	);
};

export default Footer;
