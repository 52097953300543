import { Col, Container, Row } from 'react-bootstrap';
import TextBox from './TextBox';
import {
	fadeInLeft,
	fadeInRight,
} from '../../../Litho/Functions/GlobalAnimations';
import { m } from 'framer-motion';

const TextBoxData = [
	{
		number: '01',
		title: 'Our Heritage in Gold',
		description:
			"With years of experience, we've built a legacy of trust and excellence in gold trading, evolving with the market.",
	},
	{
		number: '02',
		title: 'Focused on Value',
		description:
			'Our commitment is simple—providing secure, transparent, and valuable gold trading solutions for all our clients.',
	},
];

const OurJourney = () => {
	return (
		<section className='px-[25px] py-[130px] overflow-hidden lg:py-[90px] sm:py-[70px] sm:pb-0 xs:py-[50px] xs:px-0 bg-tsecondary'>
			<Container>
				<Row className='items-center justify-center'>
					<Col lg={4} sm={8} className='text-center text-lg-start md:mb-[50px]'>
						<m.h6
							className='font-serif w-[80%] text-tprimary font-medium mb-[20px] lg:w-[90%] md:w-full md:mb-[10px]'
							{...{
								...fadeInRight,
								transition: { duration: 0.9 },
								viewport: { once: false },
							}}
						>
							Unveiling Our Journey in Gold Trading
						</m.h6>
					</Col>
					<TextBox
						grid='row-cols-1 row-cols-md-2 col-lg-8'
						theme='text-box-style-02 flex-wrap lg:px-0'
						className='sm:mb-[30px] xs:last:mb-0'
						data={TextBoxData}
						{...{
							fadeInLeft,
							transition: { duration: 0.9 },
							viewport: { once: false },
						}}
					/>
				</Row>
			</Container>
		</section>
	);
};

export default OurJourney;
