import { Container } from 'react-bootstrap';
import PageWrapper from '../components/basic/PageWrapper';
import GoldMonthlyPriceChart from '../components/components/HomePage/GoldMonthlyPriceChart';
import AnalyticsChartSection from '../components/components/NewAndAnalysis/Charts/AdvancedChart';
import HeroSection from '../components/components/NewAndAnalysis/HeroSection';
import NewsSection from '../components/components/NewAndAnalysis/Charts/News';
import TechnicalAnalysis from '../components/components/NewAndAnalysis/Charts/TechnicalAnalysis';
import AnalysisSection from '../components/components/NewAndAnalysis/AnalysisSection';

const NewAndAnalysisPage = () => {
	return (
		<PageWrapper>
			<HeroSection />
			<Container>
				<GoldMonthlyPriceChart />
				<NewsSection />
				<AnalysisSection />
			</Container>
		</PageWrapper>
	);
};

export default NewAndAnalysisPage;
