import PageWrapper from '../components/basic/PageWrapper';
import LandingSection from '../components/components/HomePage/LandingSection';
import ActivitiesSection from '../components/sections/ActivitiesSection';
import GoldMonthlyPriceChart from '../components/components/HomePage/GoldMonthlyPriceChart';
import HomePageHelmet from '../components/components/HomePage/HomePageHelmet';
import ProductsSection from '../components/components/HomePage/ProductsSection';

const HomePage = () => {
	return (
		<>
			<HomePageHelmet />
			<PageWrapper>
				<LandingSection />
				<ActivitiesSection />
				<GoldMonthlyPriceChart />
				<ProductsSection />
			</PageWrapper>
		</>
	);
};

export default HomePage;
