import React, { Suspense, useEffect, useState } from 'react';

// Libraries
import { Routes, Route, useLocation } from 'react-router-dom';
import retina from 'retinajs';
import { AnimatePresence } from 'framer-motion';
import { HelmetProvider } from 'react-helmet-async';

// Context
import GlobalContext from './Litho/Context/Context';

// Components
import ScrollToTopButton from './Litho/Components/ScrollToTop';

// Home
import Litho from './Litho';
import HomePage from './Pages/HomePage';
import ContactUsPage from './Pages/ContactUsPage';
import AboutUsPage from './Pages/AboutUsPage';
import NewAndAnalysisPage from './Pages/NewAndAnalysisPage';
import PageNotFoundPage from './Pages/PageNotFoundPage';
import ProductsPage from './Pages/ProductsPage';

function App() {
	const [headerHeight, setHeaderHeight] = useState(0);
	const [footerHeight, setFooterHeight] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [customModal, setCustomModal] = useState({
		el: null,
		isOpen: false,
	});
	const location = useLocation();

	// RetinaJS
	useEffect(() => {
		window.addEventListener('load', retina(document.querySelectorAll('img')));
	}, []);

	useEffect(() => {
		setTimeout(() => {
			import('./Litho/Functions/Utilities').then((module) => {
				module.SetHeaderMenuPos();
				module.setDocumentFullHeight();
			});
		}, 1000);
	}, [location]);

	useEffect(() => {
		if (isModalOpen === true) {
			document.querySelector('body').classList.add('overflow-hidden');
		} else {
			document.querySelector('body').classList.remove('overflow-hidden');
		}
	}, [isModalOpen]);

	// Get the current location and set the window to top
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant',
		});
		setFooterHeight(0);
		setCustomModal({
			...customModal,
			el: null,
			isOpen: false,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<HelmetProvider>
			<GlobalContext.Provider
				value={{
					headerHeight,
					setHeaderHeight,
					footerHeight,
					setFooterHeight,
					isModalOpen,
					setIsModalOpen,
					customModal,
					setCustomModal,
				}}
			>
				<div
					className='App bg-tsecondary'
					style={{ '--header-height': `${headerHeight}px` }}
				>
					{
						<main
							style={{ marginTop: headerHeight, marginBottom: footerHeight }}
						>
							<ScrollToTopButton />
							<AnimatePresence mode='wait'>
								<Suspense fallback={<></>}>
									<Routes>
										<Route path='litho/*' element={<Litho />} />
										<Route path='/' element={<HomePage />} />
										<Route path='/contact' element={<ContactUsPage />} />
										<Route path='/about' element={<AboutUsPage />} />
										<Route path='/products' element={<ProductsPage />} />
										<Route
											path='/news-and-analysis'
											element={<NewAndAnalysisPage />}
										/>
										<Route path='/*' element={<PageNotFoundPage />} />
									</Routes>
								</Suspense>
							</AnimatePresence>
						</main>
					}
				</div>
			</GlobalContext.Provider>
		</HelmetProvider>
	);
}

export default App;
