import PageWrapper from '../components/basic/PageWrapper';
import ProductsSection from '../components/components/HomePage/ProductsSection';
import HeroSection from '../components/components/ProductsPage/HeroSection';
import ProductsPageHelmet from '../components/components/ProductsPage/ProductsPageHelmet';

const ProductsPage = () => {
	return (
		<>
			<ProductsPageHelmet />
			<PageWrapper>
				<HeroSection />
				<ProductsSection />
			</PageWrapper>
		</>
	);
};

export default ProductsPage;
